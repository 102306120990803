.UserControl {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
}

.SignOut,
.Tools {
  border: none;
  color: #fff;
  margin-right: 10px;
  font-size: 10px;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #3f3f44;
  transition: all 0.4s;
}

.SignOut:hover,
.Tools:hover {
  color: rgb(223, 223, 223);
}

.userProfile {
  text-decoration: none;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.UserPanel {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.AvatarLogo {
  margin-right: 5px;
}

.AvatarLogo img {
  width: 3.5rem;
}

.Info {
  display: flex;
}
