body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif !important;
}

.thumbImages{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.thumbImage{
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap:10px;
}

.thumbImage > img { 
  width: 100%;
}

.form-select {
  font-size: 0.82rem !important;
}

#custom-tabs-four-tab .nav-item a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
}

.card-primary.card-outline-tabs > .card-header a.active {
  border-top: 3px solid #677685;
}
#custom-tabs-four-tab .nav-item a {
  color: rgb(44, 44, 44);
  padding: 10px 20px;
}
#custom-tabs-four-tab .nav-item a.active {
  color: #054680;
}
.form-group p {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: rgb(59, 49, 49);
  font-weight: 500;
}

.form-group input {
  font-size: 0.8rem;
  padding-left: 14px;
}

.redText {
  color: red;
}

.form-group-my.input-group-sm {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 0 auto;

  margin-bottom: 10px;
}

.form-group-my input,
.form-group-my select {
  width: 50%;
}

.form-group-my p {
  width: 35%;
}

.productDetails {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
}

.productImage img {
  width: 100%;
}

.productField a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  background-color: #054680;
  padding: 8px 20px;
}

.btns-order {
  margin-top: 20px;
}

.messageBox {
  width: 100%;
}

.messageBox textarea {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 12px;
}

.orderUser {
  display: flex;
  flex-direction: column;
}

.userField {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  align-items: center;
  margin-bottom: 10px;
}

.userField h5 {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  margin-right: 10px;
}

.userField a {
  color: #054680;
  text-decoration: none;
}

.messageBox button {
  border: none;
  padding: 5px 20px;
  background-color: #ec6b2e;
  color: #fff;
  font-size: 12px;
  transition: all 0.3s;
}

.messageBox button:hover {
  background-color: #e25513;
}

.messages {
  padding: 0px;
  margin: 0px;
  margin-bottom: 15px;
  font-size: 12px;
}

.messages li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding: 10px;
  background-color: rgb(235, 235, 235);
  margin-bottom: 10px;
}

.sortbox{
  margin-bottom: 30px;
}

.sortInput,
.msl-vars{
  --menu-max-height: 300px;
  --input-width: 100%;
  --font-size: 12px;
  --border-radius: 2px 2px 2px 2px;
  --active-menu-shadow: 1px 1px 5px 0px grey;
  --line-height: 1.4;
  --active-menu-background: #fff;
  --active-menu-radius: var(--border-radius);
  padding:0px !important;
  margin: 0px !important;
}