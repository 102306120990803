.Thumbs {
  display: flex;
  flex-direction: column;
}

.ThumbInner {
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  height: auto;
  padding: 4px;
  box-sizing: border-box;
}

.Img {
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.Btn {
  position: absolute;
  z-index: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: -10px;
  background-color: rgb(185, 185, 185);
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  right: -10px;
  cursor: pointer;
  font-size: 10px;
}
