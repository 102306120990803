.FormGroup {
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.ScrollBtn {
  position: relative;
  padding: 8px 15px;
  border: none;
  background: none;
  transition: all 0.2s;
  min-width: 150px;
  align-items: left;
  font-size: 0.9rem;
}

.Dropdown {
  width: 100%;
  top: 100%;
  left: 0;
  display: none;
  position: absolute;
  z-index: 1000;
  background: #fff;
  padding-top: 5px;
  -webkit-box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.46);
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.46);
  max-height: 200px;
  overflow-y: auto;
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.Dropdown::-webkit-scrollbar {
  width: 11px;
}

.Dropdown::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.Dropdown::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.Dropdown li {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.Dropdown li > ul {
  padding-inline-start: 12px;
}

.Dropdown li p {
  margin-bottom: 0px;
  padding: 5px;
  padding-left: 15px;
}

.ScrollBtn:hover {
  background: rgb(226, 226, 226);
}

.Open > button {
  background-color: #fff;
  z-index: 1001;
}

.ScrollBtn > i {
  margin-left: 7px;
}
