.subTitle {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: rgb(90, 90, 90);
  margin-bottom: 30px;
}

.Backbutton {
  margin-left: 10px;
  border-radius: 0px;
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 3px !important;
  padding: 8px 20px !important;
  border: 1.4px solid #ccc;
  transition: all 0.3s ease-in-out;
}

.Backbutton:hover {
  background-color: rgb(228, 228, 228);
}

.Backbutton i {
  margin-right: 8px;
  font-size: 10px !important;
}

.Title > i {
  margin-right: 10px;
}

.bankHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OldLogo {
  height: 100px;
}

img.OldLogo {
  height: 100px;
  width: auto;
}

.bankHeader > .subTitle {
  margin-bottom: 0px;
}

.LitleText {
  margin-top: -10px;
  font-size: 0.7rem !important;
  color: rgb(233, 10, 10) !important;
}

.PreviewImg {
  width: 20%;
}

.BankAccounts {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.WhiteLogo {
  background-color: #000;
}

.BankDelete {
  font-size: 0.75rem;
  color: red;
  cursor: pointer;
}

.Toast {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100000;
  min-width: 300px;
  width: auto;
  background-color: #fff;
}

.WebinfoControl {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.Bank {
  font-weight: 600;
  margin-bottom: 0px;
}

.BankAddressDetail {
  margin-top: 20px;
}

.BankAddressDetials {
}
